@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.feedback {
  &_title {
    color: var(--text, #3b3e45);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 20px;
  }
  &__header {
    display: flex;
  }
  &__status {
    margin: 0 10px;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    &_active {
      height: 20px;
      color: #479323;

      border-radius: 5px;
      border: 1px solid #479323;
      background: rgba(71, 147, 35, 0.2);
      padding: 5px;
    }
    &_notactive {
      height: 20px;
      padding: 5px;
      color: #b8441f;
      border-radius: 5px;
      border: 1px solid #b8441f;
      background: rgba(184, 68, 31, 0.2);
    }
  }
  &__delete {
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    padding: 5px;
  }
}

.pagination {
  bottom: 0;
  right: 0;
  text-align: end;
  padding-right: 20px;
  width: calc(100% - 280px);
}

.select {
  height: 40px;
  border-radius: 8px;
  background: #f6f6f6;
  width: 100%;
  margin: 5px;
}

.filter {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.filterTitle {
  color: #343434;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.suffix {
  display: flex;
  align-items: center;
}

.title {
  color: #343434;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px 0;
}
