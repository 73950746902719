@import "../../scss/variables.scss";

.loading {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success {
  color: #479323;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.error {
  color: #b8441f;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
