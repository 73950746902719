@import "../../scss/variables.scss";

.search {
  background-color: $bg_input !important;
  border-radius: 8px;
  height: 40px;
  //width: 200px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  width: calc(80% - 10px);
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30%;
  .oneIcon {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 22px;
    cursor: pointer;
    &:last-child {
      margin-right: 0px;
    }
  }
  .textIcon {
    padding-left: 5px;
  }
}

.burger {
  display: none;
  height: 100%;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  font-family: "Manrope";
  font-weight: 500;
  font-size: 14px;
  position: relative;
  &_logo {
    display: flex;
    align-items: center;
    width: 80%;
    //width: 50%;
  }
}

.logo {
  width: "20%";
  height: 100%;
  cursor: pointer;
  margin-right: 20px;
}

.oneIcon {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.textIcon {
  margin-left: 10px;
  white-space: nowrap;
}

.contacts {
  display: none;
}

@media (max-width: 1080px) {
  .wrapper {
    flex-direction: column;
    /*   display: flex;

    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    font-family: "Manrope";
    font-weight: 500;
    font-size: 14px;
    position: relative; */

    &_logo {
      width: 100%;
    }
  }

  .contacts {
    display: block;
    width: 40%;
    padding: 5px;
  }
  .icons {
    width: 100%;
  }
  .oneIcon {
    margin: 0 !important;
    padding: 5px;
    width: 20%;
  }
}

.succes {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}

@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.title {
  @include title_admin;
}

.wrapper {
  padding-bottom: 50px;
  position: relative;
}

.rightNavbarList {
  //border: 0.5px solid rgba(190, 190, 190, 0.8);
  box-shadow: 0px 6px 11px 0px rgba(193, 196, 207, 0.25);

  position: fixed;
  top: 0;
  right: 0;
  height: 100%;

  background: rgb(225, 233, 246);
  background: linear-gradient(
    0deg,
    rgba(225, 233, 246, 1) 45%,
    rgba(154, 193, 252, 1) 100%
  );

  width: 300px;
  color: #343434;

  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 5px;
  &_header {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    color: #fff;
    button {
      margin: 0 10px;
    }
  }
  &_icon {
    cursor: pointer;

    //color: grey;
  }
}

.table {
  //opacity: 0.9;
}

.footer {
  width: 100%;

  &_price {
    color: var(--icon, #878d9c);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }
  div:last-child {
    color: #3b3e45;
    font-size: 16px;
  }

  &_button {
    max-width: 230px;
    margin-left: 50%;
    transform: translateX(-50%);
    color: #fff;
    height: 40px;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 80%;
    border-radius: 8px;
    background: #005fef;
    box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.status {
  color: #479323;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid #479323;
  background: rgba(71, 147, 35, 0.2);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}

.status_disabled {
  color: #b8441f;
  border: 1px solid #b8441f;
  background: rgba(184, 68, 31, 0.2);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  bottom: 0;
  right: 0;
  text-align: end;
  padding-right: 20px;
  width: calc(100% - 280px);
}

.button {
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 40px;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background: #005fef;
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
  div:last-child {
    margin-left: 10px;
  }
}

.listButtons {
  display: flex;
  margin-right: 10px;
}

.input {
  height: 40px;
  background-color: #f6f6f6;
}

.modalButton {
  display: flex;
  justify-content: center;
}

.succes {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}

/* .img_block {
  width: 50px;
  height: 50px;
}

.upload {
  width: 50px;
  height: 50px;
}
 */

.img_block {
  span {
    div {
      margin-top: 7px;
      div {
        width: 50px !important;
        height: 50px !important;

        span {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.preview {
  display: flex;
  //align-items: flex-end;
  &__img {
    //margin-top: 15px;
  }
  &__input {
    width: calc(100% - 80px);
  }
}

.upload {
  width: 40px;
  height: 40px;
}

.img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
