@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.pagination {
  position: absolute;
  left: 6%;
  bottom: 70px;
}

.main_wrapper {
  display: flex;
  padding: 0 5%;
  width: 90%;
}

.wrapper_goods {
  display: flex;
}

.filters {
  padding: 15px;
  &__item {
    border-bottom: 0.5px solid var(--line, rgba(190, 190, 190, 0.8));
    //box-shadow: 0px 6px 11px 0px rgba(193, 196, 207, 0.25);
    height: 40px;
    display: flex;
    align-items: center;
  }
  &__name {
    color: var(--text, #3b3e45);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__values {
    color: var(--text, #3b3e45);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    //box-shadow: 0px 6px 11px 0px rgba(193, 196, 207, 0.25);
    padding: 32px 0;
    //background: #fff;
  }
}

.wrapper__name {
  background: #eaeaea;
  box-shadow: 0px 6px 11px 0px rgba(193, 196, 207, 0.25);
  border-radius: 8px;
  margin-top: 15px;
}
