@import "../../../scss/variables.scss";

.count_block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 92px;
  border-radius: 8px;
  border: 1px solid #f6f6f6;
  background: #f6f6f6;
  height: 35px;

  input {
    border: 0;
    width: 30px;
    text-align: center;
    background: #f6f6f6;
  }
  button {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    opacity: 0.5;
    background: #dedede;
    border: none;
  }
}

.good {
  //width: 20vw;
  width: 90%;
  max-width: 400px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
  color: #878d9c;
  font-family: "Manrope";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 15px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 15px;
  &_leftPart {
    &__horiz {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &_horiz {
    padding: 25px;
    display: flex;
    //width: 100%; ???
    max-width: 1920px;
    align-items: center;
    justify-content: space-between;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_content {
    position: relative;
  }
  &_desc {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    &__underline {
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
  &_likes {
    &__horiz {
      position: absolute;
      top: -25px;
      left: -20px;
    }
  }
  &_sub_desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 15px 0;
  }
  &_artical {
    font-size: 12px;
  }
  &_footer {
    &__horiz {
      //width: 50%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: start;
      .good_coast {
        margin-right: 30px;
      }
    }
  }
  &_supplier {
    font-weight: 500;
    font-size: 12px;
    color: #3b3e45;
  }
  &_coast {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .price {
    }
    div:last-child {
      font-size: 16px;
      font-weight: 600;
      color: #3b3e45;
    }
  }
  &_button {
    display: flex;
    width: 100%;

    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Manrope;
    font-size: 13px;
    font-weight: 600;
    div:last-child {
      margin-left: 5px;
    }
  }
  &_img {
    width: 100%;
    text-align: center;
    margin: 15px 0;
    &__horiz {
      width: 120px;
      height: 120px;
    }
  }

  &_feedback {
    color: #005fef;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-left: 10px;
  }
}

@mixin def_but_item {
  height: 38px;
  margin-top: 15px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
}
.button_item {
  @include def_but_item;
  background: #005fef;
  max-width: 300px;
}

.button_item_out {
  @include def_but_item;
  background: #878d9c;
}

.goods_wrapper {
  width: 100%;
  // margin-bottom: 85px;
}

.price {
  min-width: 100px;
}
