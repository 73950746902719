@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.layout {
  height: 100vh;
  position: relative;
}
.header {
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $bg_header;
  @include alignCenterBlock;
}

.content {
  margin-top: 160px;
  //height: calc(100% - 70px - 70px);
  width: 100%;
  max-width: 1920px;
  background-color: $bg_content;
  overflow-y: auto;
  padding: 0 50px;
  border: 2px solid #e7e7e7;
  box-shadow: 0px 7px 10px 0px rgba(135, 141, 156, 0.5);
  @include alignCenterBlock;
  padding-bottom: 80px;
  &_withoutBreadcrumb {
    margin-top: 120px;
  }
  &_withoutFooter {
    padding-bottom: 10px;
  }
}

.footer {
  height: 70px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $bg_footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  @include alignCenterBlock;
}

.footer_menu {
  display: flex;
  div {
    margin: 0 20px;
    cursor: pointer;
  }
}

.social_network {
  display: flex;
  align-items: center;
  margin-right: 20px;

  svg {
    margin: 0 5px;
    cursor: pointer;
  }
}

.banner {
  position: relative;
  width: 100%;
  margin-top: 5px;
  z-index: 1;
  height: 40%;
  &_text {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    padding-left: 40px;
  }
  &_name {
    color: #fff;
    font-family: Poppins;
    //font-size: 57.412px;
    font-size: 3vw;
    font-style: normal;
    font-weight: 600;
  }
  &_desc {
    color: #e2e2e2;
    font-family: Manrope;
    //font-size: 18px;
    font-size: 1vm;
    font-style: normal;
    font-weight: 500;
    margin-top: 10px;
  }

  &_button {
    color: #fff;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    height: 36px;
    width: 220px;
    border-radius: 8px;
    background: #005fef;
    box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
    margin-top: 25px;
  }
}

.text_desc {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  div:first-child {
    color: #343434;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  div:last-child {
    color: #878d9c;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
}

.floatB {
  border-radius: 10px;
  background: #878d9c;
  box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
}

.menu_breadcrumb {
  @include breadcrumb;
}

.float_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  padding: 15px;
  border-radius: 10px;
  background: #878d9c;
  box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
  color: #fff;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  bottom: 80px;
  cursor: pointer;
}

.account {
  width: 100%;
  display: flex;
  height: calc(100% - 15px);
  margin-top: 5px;
  position: relative;

  &_menu {
    width: 30%;
    position: absolute;
    top: 0;
  }
  &_content {
    width: 70%;
    margin-left: 30%;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
    padding: 25px;
  }
}

@media (max-width: 1080px) {
  .header {
    height: calc(70px + 70px);
  }

  .content {
    margin-top: 160px + 70px;
    &_withoutBreadcrumb {
      margin-top: 120 + 70px;
    }
  }

  .menu_breadcrumb {
    margin-top: 120px + 70px;
  }
}
