
/* Manrope */
@font-face {
    font-family: 'Manrope';
    src: url('./Manrope-Regular.ttf');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'Manrope';
    src: url('./Manrope-Medium.ttf');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: 'Manrope';
    src: url('./Manrope-SemiBold.ttf');
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: 'Manrope';
    src: url('./Manrope-Bold.ttf');
    font-weight: 700;
    font-style: normal
}

/* Poppins */
@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Regular.ttf');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Medium.ttf');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-SemiBold.ttf');
    font-weight: 600;
    font-style: normal
}
