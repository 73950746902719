@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.details {
  &_title {
    @include title_account;
  }
  &_form {
    width: 100%;
  }
  &_formColum {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  &_subTitle {
    color: #343434;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    width: 30%;
  }
  &_column {
    display: flex;
    width: 100&;
    margin-top: 20px;
  }
}

.input {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 300px;
  border-radius: 8px;
  background: #f6f6f6;
  height: 40px;
  color: #3b3e45;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  //margin: 5px;
}

.label {
  color: #3b3e45;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 40px;
  color: #fff;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background: #005fef;
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
  &_wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  span {
    margin-left: 5px;
  }
  &_sec {
    background: #878d9c;
    margin-right: 10px;
  }
}
