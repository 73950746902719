.select {
  height: 40px;
  border-radius: 8px;
  background: #f6f6f6;
  width: 100%;
  margin: 5px;
}

.suffix {
  display: flex;
  align-items: center;
}
