.container {
  background: #fff;
  border-radius: 8px;
  height: fit-content;
  width: 100%;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
}

.paymentForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.orderForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  height: fit-content;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.paymentForm label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #333;
}

.cardField {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #fff;
}

.inputField {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #fff;
  font-size: 16px;
}

.inputField:focus {
  border-color: #ccc;
  outline: none;
}

.inputField::placeholder {
  color: #aab7c4;
}

.button {
  background-color: #005fef;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border: none;
  //border: 1px solid #f9f9f9;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button:disabled {
  background-color: #a0a0a0;

}

.button:hover:not(:disabled) {
  //background-color: #a0a0a0;
  color: #689ae8;
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
  //border: 1px solid #005fef
}


.card {

  margin: 5px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: fit-content;
  opacity: 50%;
  height: fit-content;
}


.addNew {

  width: fit-content;
  border-radius: 10px;
  display: flex;
  margin: 0 auto;
}