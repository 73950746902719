@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.banner {
  position: relative;
  width: 100%;
  margin-top: 5px;
  z-index: 1;
  height: 40%;
  &_text {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    padding-left: 40px;
  }
  &_name {
    color: #fff;
    font-family: Poppins;
    //font-size: 57.412px;
    font-size: 3vw;
    font-style: normal;
    font-weight: 600;
  }
  &_desc {
    color: #e2e2e2;
    font-family: Manrope;
    //font-size: 18px;
    font-size: 1vm;
    font-style: normal;
    font-weight: 500;
    margin-top: 10px;
  }

  &_button {
    color: #fff;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    height: 36px;
    width: 220px;
    border-radius: 8px;
    background: #005fef;
    box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
    margin-top: 25px;
  }
}

.text_desc {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  div:first-child {
    color: #343434;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  div:last-child {
    color: #878d9c;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
}

.pagination {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 0 0px;
  padding-top: 10px;
}

.wrapper {
  min-height: 100%;
  //height: calc(100%);

  position: relative;
  padding-bottom: 100px;
}

.goods {
  display: flex;
}

.filter {
  background-color: #eaeaea;
  width: 280px;
  margin-right: 30px;
  margin-top: 15px;
}
