@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.cart {
  &_wrapper {
    display: flex;
  }
  &_goods {
    width: 60%;
  }
  &_payment {
    width: 40%;
    //height: 330px;
    //min-height: 250px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
    margin-top: 15px;
    margin-left: 15px;
    padding: 15px;
    &__title {
      color: #343434;
      text-align: center;
      font-family: Manrope;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &__price {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #878d9c;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 20px;
    }
    &__total {
      color: #3b3e45;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 10px;
    }
  }
  &_code {
    border-radius: 5px;
    background: #f6f6f6;
    margin-top: 10px;
    height: 35px;
  }

  &_button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Manrope;
    font-size: 13px;
    font-weight: 600;
    div:last-child {
      margin-left: 5px;
    }
  }
}

@mixin def_but_item {
  height: 38px;
  margin-top: 15px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
}

.button_item {
  @include def_but_item;
  background: #005fef;
}

.pagination {
  position: absolute;
  left: 6%;
  bottom: 70px;
}
