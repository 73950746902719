@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.wrapper {
  position: relative;
}
.row {
  border-right: 0.5px solid rgba(190, 190, 190, 0.8);
  color: #3b3e45;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.status {
  color: #479323;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid #479323;
  background: rgba(71, 147, 35, 0.2);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status_disabled {
  color: #b8441f;
  border: 1px solid #b8441f;
  background: rgba(184, 68, 31, 0.2);
}

.table {
  text-align: center;
  &_wrapper {
    margin-bottom: 50px;
  }
}
.headerColumn {
  text-align: center;
  width: 100%;
}

.title {
  @include title_admin;
}

.button {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 40px;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background: #005fef;
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
  div:last-child {
    margin-left: 10px;
  }
}

.listButtons {
  display: flex;
  margin-right: 10px;
}

.rightNavbarList {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;

  //background-color: #ceecff;
  //opacity: 0.8;

  background: rgb(225, 233, 246);
  background: linear-gradient(
    0deg,
    rgba(225, 233, 246, 1) 45%,
    rgba(154, 193, 252, 1) 100%
  );

  width: 350px;
  color: #343434;

  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 5px;
  &_header {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    padding: 0 10px;
    button {
      margin: 0 10px;
    }
  }
  &_icon {
    cursor: pointer;
  }
}

.title {
  @include title_admin;
  flex-wrap: wrap;
  height: auto;
  margin-top: 10px;
  gap: 10px;
  margin-bottom: 10px;

  &__text {
    width: 25%;
  }
  &__search {
    width: 45%;
    height: 100%;
    button {
      backgroundcolor: "#1677ff";
    }
  }
}
