@import "../../scss/variables.scss";

.search {
  background-color: $bg_input !important;
  border-radius: 8px;
  height: 40px;
  //width: 200px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  //width: calc(80% - 10px);
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30%;
  margin-left: 35px;
  .oneIcon {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 22px;
    cursor: pointer;
    &:last-child {
      margin-right: 0px;
    }
  }
  .textIcon {
    padding-left: 5px;
  }
}

.burger {
  display: none;
  height: 100%;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  font-family: "Manrope";
  font-weight: 500;
  font-size: 14px;
  position: relative;
  &_logo {
    display: flex;
    align-items: center;
    width: 80%;
    //width: 50%;
  }
}

.logo {
  width: "20%";
  height: 100%;
  cursor: pointer;
  margin-right: 20px;
}

.oneIcon {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.textIcon {
  margin-left: 10px;
  white-space: nowrap;
}

.contacts {
  display: none;
}

@media (max-width: 1080px) {
  .wrapper {
    flex-direction: column;
    /*   display: flex;

    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    font-family: "Manrope";
    font-weight: 500;
    font-size: 14px;
    position: relative; */

    &_logo {
      width: 100%;
    }
  }

  .contacts {
    display: block;
    width: 40%;
    padding: 5px;
  }
  .icons {
    width: 100%;
    margin-left: 0px;
  }
  .oneIcon {
    margin: 0 !important;
    padding: 5px;
    width: 20%;
  }
}

.badge {
  display: flex;
  align-items: center;
}
.succes {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}

/* .oneIcon {
  display: flex;
  align-items: center;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: inherit;
  span {
    margin-left: 5px;
  }
} */

.confirm {
  color: var(--text, #3b3e45);
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
}
