@import "../../scss/variables.scss";

@import "../../scss/variables.scss";

@import "../../scss/variables.scss";

.account {
  width: 100%;
  display: flex;
  min-height: calc(100% - 130px);
  //margin-top: 5px;
  position: relative;

  //padding-top: 80px;

  &_menu {
    width: 20%;
    position: absolute;
    //top: 70px;
  }
  &_content {
    width: 100%;
    //margin-left: 20%;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
    padding: 25px;
    margin-top: 30px;
  }
}

.oneIcon {
  color: var(--text, #3b3e45);
  font-family: Manrope;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;

  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.textIcon {
  margin-left: 10px;
  white-space: nowrap;
}

.title {
  color: var(--text, #3b3e45);
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 50px 0;
  margin-left: 10px;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;

  &__text {
    //width: 40%;
    color: var(--text, #3b3e45);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    div:last-child {
      color: var(--icon, #878d9c);
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }
  &__select {
    width: 30%;
  }
}

.select {
  height: 40px;
  border-radius: 8px;
  background: #f6f6f6;
  width: 100%;
  margin: 5px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 40px;
  color: #fff;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background: #005fef;
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
  &_wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  span {
    margin-left: 5px;
  }
  &_sec {
    background: #878d9c;
    margin-right: 10px;
  }
}
/* 
.button__delete {
  border-radius: 8px;
  background: var(--icon, #878d9c);
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
}

.wrapperButtonD {
  display: flex;
  justify-content: end;
  margin-top: 5px;
  margin-bottom: 5px;
}
 */
