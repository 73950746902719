@import "../../scss/variables.scss";

@mixin cnts_def {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  display: flex;
  align-items: center;
  margin-right: 50px;
  padding: 0 35px;
  cursor: pointer;
}
.contacts {
  @include cnts_def;
}

.contacts_enter_mouse {
  @include cnts_def;
  background-color: #f1f0f0;
}

.contact_text {
  font-family: "Manrope";
  margin-left: 10px;

  div:first-child {
    color: #3b3e45;
    font-size: 14px;
    font-weight: 600;
  }
  div:last-child {
    color: #878d9c;
    font-size: 12px;
    font-weight: 400;
  }
}

.dropdown_content {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
  width: 100%;
  color: #3b3e45;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding: 10px;
}

.dropdown_icon {
  color: #878d9c;
  width: 30%;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
}

.dropdown_text {
  width: 70%;
  div {
    margin-bottom: 5px;
  }
}
.dropdown_item {
  display: flex;
  align-items: start;
  width: 100%;
}

.dropdown_header {
  color: #3b3e45;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width: 1080px) {
  .contacts {
    position: relative;
    margin-right: 0px;
    padding: 5px;
    height: 70px;
    /* margin-left: 50%;
    transform: translateX(-50%); */
  }
  .contacts_enter_mouse {
    position: relative;
    margin-right: 0px;
    background-color: #f1f0f0;
    padding: 5;
  }

  .contact_text {
    font-family: "Manrope";
    margin-left: 10px;

    white-space: nowrap;
    div:first-child {
      color: #3b3e45;
      font-size: 14px;
      font-weight: 600;
      height: 20px;
    }
    div:last-child {
      color: #878d9c;
      font-size: 12px;
      font-weight: 400;
    }
  }
}
