@import "./variables.scss";

@mixin alignCenterBlock {
  max-width: 1920px;
  margin-left: 50%;
  transform: translateX(-50%);
}

@mixin breadcrumb {
  font-family: Manrope;
  height: 40px;
  background: #f6f6f6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-bottom: 0.5px solid var(--line, rgba(190, 190, 190, 0.8));
  position: absolute;
  width: 100%;
  z-index: 3;
  margin-top: 120px;
  padding-left: 50px;
  display: flex;
  align-items: center;
}

@mixin title_account {
  color: #343434;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

@mixin title_admin {
  height: $title_admin_height;
  color: #343434;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
