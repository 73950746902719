@import "../../scss/variables.scss";

.menu {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin-left: 10px;
  }
}

.product {
  position: relative;
  height: calc(100% - 80px);
  overflow-y: scroll;
}

