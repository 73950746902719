@import "../../scss/variables.scss";

@import "../../scss/variables.scss";

@import "../../scss/variables.scss";

.wrapper {
  display: flex;
  //padding: 20px;
  width: 100%;
  margin-bottom: 20px;
}
.img_block {
  border-radius: 10px;
  border: 1px dashed var(--line, rgba(190, 190, 190, 0.8));
  background: #fff;
  height: 600px;
  //width: calc(100% - 20px);
  padding: 20px;
  //width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-between;
}

.leftPart,
.rightPart {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}
.leftPart {
  margin-left: 20px;
}

.select {
  height: 40px;
  border-radius: 8px;
  background: #f6f6f6;
  width: 100%;
  margin: 5px;
}

.form {
  padding: 20px;
  //width: 100%;

  &__row {
    //width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    width: 500px;
    overflow-x: auto;
  }
  &__item {
    color: var(--text, #3b3e45);
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: calc(100%);
  }
  &__input {
    border-radius: 8px;
    background: #f6f6f6;
    height: 40px;
    margin: 5px;
    input {
      background: #f6f6f6 !important;
    }
  }
}

.highlights {
  height: 300px;
  overflow-y: scroll;
}

.desc {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  color: var(--text, #3b3e45);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  textarea {
    color: var(--text, #3b3e45);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: #f6f6f6;
    margin-top: 10px;
  }
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  &__text {
    // width: 40%;
    color: var(--text, #3b3e45);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    div:last-child {
      color: var(--icon, #878d9c);
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }
  &__select {
    width: 60%;
  }
}

.product {
  padding: 20px;
}

.upload {
  margin-top: 50%;
  transform: translateY(-50%);
  height: 150px;
  &_preview {
    margin-top: 20px;
    height: 110px;
  }
}

.img {
  width: 100%;
  height: 100%;
  img {
    object-fit: contain;
  }
}

.feedback {
  &_title {
    color: var(--text, #3b3e45);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 20px;
  }
  &__header {
    display: flex;
  }
  &__status {
    margin: 0 10px;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    &_active {
      height: 20px;
      color: #479323;
      border-radius: 5px;
      border: 1px solid #479323;
      background: rgba(71, 147, 35, 0.2);
      padding: 5px;
    }
    &_notactive {
      height: 20px;
      padding: 5px;
      color: #b8441f;
      border-radius: 5px;
      border: 1px solid #b8441f;
      background: rgba(184, 68, 31, 0.2);
    }
  }
  &__delete {
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    padding: 5px;
  }
}

.close {
  margin: 10px 0;
}

.tag {
  border-radius: 5px;
  border: 1px solid #479323;

  background: rgba(71, 147, 35, 0.2);
  color: #479323;

  font-family: Manrope;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 30px;
  display: flex;
  align-items: center;
}

.rightNavbarList {
  height: 100%;
  position: relative;
  //background-color: #ceecff;
  //opacity: 0.8;

  //width: 100%;
  color: #343434;

  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 20;
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //color: #fff;
    //padding: 0 10px;
    padding-bottom: 5px;
    button {
      margin: 0 10px;
    }
  }
  &_icon {
    cursor: pointer;
  }
}

.buttons {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  button {
    border-radius: 8px;
    background: var(--main, #005fef);
    box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
    color: #fff;

    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 40px;
    width: 180px;
  }
}

.headerMain {
  color: var(--text, #3b3e45);
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 20px 0;
}

.pagination {
  //background-color: #005fef;
  position: absolute;
  width: 100%;
  bottom: 15px;
  right: 0;
  background-color: transparent;
}

.openBtn {
  display: none;
}

.close {
  display: none;
}

@media only screen and (max-width: 1363px) {
  .leftPart {
    position: absolute;
    right: 0;
    opacity: 0.8;
    top: -90px;
    right: -20px;
    height: 100vh;
    z-index: 5;
    display: none;
    &_open {
      display: block;
    }
  }

  .pagination {
    //background-color: #005fef;
    position: relative;
    width: 100%;
    margin-top: 20px;
  }

  .openBtn {
    display: block;
  }

  .close {
    display: block;
  }
}

.inputTable {
  height: 40px;
  background-color: #f6f6f6 !important;
  //color: #878d9c;
  font-family: "Manrope";
  border-radius: 8px;
  background: #f6f6f6;
  font-size: 16px;
}
