@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.title {
  @include title_admin;
}

.wrapper {
  padding-bottom: 50px;
  position: relative;
}

.footer {
  width: 100%;

  &_price {
    color: var(--icon, #878d9c);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }
  div:last-child {
    color: #3b3e45;
    font-size: 16px;
  }

  &_button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 230px;
    margin-left: 50%;
    transform: translateX(-50%);
    color: #fff;
    height: 40px;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 80%;
    border-radius: 8px;
    background: #005fef;
    box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
  }
}

.status {
  color: #479323;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid #479323;
  background: rgba(71, 147, 35, 0.2);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}

.status_disabled {
  color: #b8441f;
  border: 1px solid #b8441f;
  background: rgba(184, 68, 31, 0.2);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  bottom: 0;
  right: 0;
  text-align: end;
  padding-right: 20px;
  width: calc(100% - 280px);
  &_h {
    bottom: 0;
    right: 0;
    text-align: end;
    width: 510px;
    padding-right: 0px;
  }
}

.button {
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 40px;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background: #005fef;
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
  div:last-child {
    margin-left: 10px;
  }
}

.listButtons {
  display: flex;
  margin-right: 10px;
}

.rightNavbarList {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  //background-color: #ceecff;
  //opacity: 0.8;
  background: rgb(225, 233, 246);
  background: linear-gradient(
    0deg,
    rgba(225, 233, 246, 1) 45%,
    rgba(154, 193, 252, 1) 100%
  );
  width: 500px;
  color: #343434;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 5px;
  &_header {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 0 10px;
    button {
      // margin: 0 10px;
    }
  }
  &_icon {
    cursor: pointer;
  }
}

.search {
  ///background-color: #3b3e45;
  ///
  span span span {
    //height: 32px !important;
    button {
      padding-top: 4px !important;
    }
  }
}

.title {
  @include title_admin;
  flex-wrap: wrap;
  height: auto;
  margin-top: 10px;
  gap: 10px;

  &__text {
    width: 200px;
  }
  &__search {
    width: 45%;
    height: 100%;
    button {
    }
  }
}

.wrapperTable {
  height: 85%;
  width: 100%;
  overflow-y: auto;
  overflow-x: visible;
}
