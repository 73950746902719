.container {
  //border: 1px solid darkblue;
  width: 200px;
  height: 130px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #25baf1;

  .visa {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    gap:80px;

  }
;
.delete {
  cursor: pointer;
  //padding-right: 5px;
  color: white;
  width: 25px;
  height: 25px;

}
  .chip {
    display: flex;
    justify-content: end;


  }
  .number{
    color: white;
    display: flex;
    justify-content: start;
    padding-left: 5px;
  }
  .exp {
    display: flex;
    justify-content: end;
    color: white;
    padding-right: 5px;
  }
}