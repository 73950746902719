@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.goodDesc_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.carousel {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
  width: 30%;
  height: 300px;
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_preview {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    div {
      width: 70px;
      height: 70px;
      border-radius: 10px;
      opacity: 0.7;
      cursor: pointer;
    }
    &__active {
      opacity: 1 !important;
    }
  }
}

.sub_desc {
  display: flex;
  margin: 15px 0;
}

.desc {
  width: 70%;
  padding-left: 20px;
  &_title {
    color: #3b3e45;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  &_artical {
    color: #878d9c;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 20px;
  }
  &_supplier {
    color: #3b3e45;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 20px;
  }
  &_advantages {
    color: #3b3e45;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 20px 0;
    span {
      color: #729d51;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-right: 5px;
    }
  }
}

.highlights {
  &_title {
    color: #3b3e45;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &_icon {
    background-color: #878d9c;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: white;
  }
  &_item {
    display: flex;
    align-items: center;
    margin: 5px;
  }
  &_desc {
    display: flex;
    flex-wrap: wrap;
    color: #3b3e45;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-top: 20px;
  }
}

.menu {
  margin-top: 20px;
  &_button {
    width: 138px;
    height: 35px;
    border-radius: 0;
    font-weight: 500;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-right: 30px;

    outline: none;
    border: none;
    background: transparent;
    color: #878d9c;
    box-shadow: none;
    &__active {
      border-radius: 8px;
      background: #878d9c;
      color: #fff;
    }
  }
}

.fulldesc {
  margin-top: 10px;
  font-style: normal;
  color: #3b3e45;
  font-family: Manrope;
  &_title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
  }
  &_text {
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
  }
}

.table {
  width: 100%;
  color: #3b3e45;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 40px;
    padding: 15px;
  }
  &_name {
    font-weight: 400;
  }
}

@mixin def_but_item {
  height: 38px;
  margin-top: 15px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
}
.button_item {
  @include def_but_item;
  background: #005fef;
  display: flex;
  width: 200px;
}
/* 
.feedback_block {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
  padding: 15px;
  color: var(--text, #3b3e45);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.feedback_who {
  color: var(--text, #3b3e45);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  span {
    color: var(--icon, #878d9c);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
 */

.feedback {
  width: 100%;
  color: #3b3e45;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  &_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &_title {
    color: #3b3e45;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_block {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
    padding: 20px;
    margin-bottom: 30px;
  }
  &_rate {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #878d9c;
  }
  &_who {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 14px;
    span {
      color: #878d9c;
      font-size: 12px;
      margin-left: 5px;
    }
  }

  &_text {
    color: #3b3e45;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
