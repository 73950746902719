.form_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.input {
  height: 40px;
  background-color: #f6f6f6;
}

.input_wrapper {
  margin-bottom: 5px;
}

.label {
  font-family: "Manrope";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  color: #9d9fa2;
}

.button {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 15px;
  width: 200px;
  height: 36px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
}

.title {
  color: #3b3e45;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 5px 0;
}

.wrapper_form {
  padding-top: 30px;
}

.forgot_password {
  color: #878d9c;
  text-align: center;
  font-family: "Manrope";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.goBack {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  span {
    margin-left: 5px;
    color: #878d9c;
  }
}

.spinner {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
