@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";
.menu_wrapper {
  height: 50px;
  width: 100%;
  position: absolute;
  top: 70px;
  left: 0;
  padding: 0 50px;
  border-top: 0.5px solid var(--line, rgba(190, 190, 190, 0.8));
  background: #fff;
  box-shadow: 0px 6px 11px 0px rgba(193, 196, 207, 0.25);
  z-index: 2;
  @include alignCenterBlock;
}

.sub_categories {
  width: 100%;
  background: #fff;
  box-shadow: 0px 7px 10px 0px rgba(135, 141, 156, 0.5);
  margin-top: 5px;
  height: 350px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 40px;
}

.one_sub_category {
  width: 100%;
  text-align: center;
  padding: 10px 0 5px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.one_sub_category_enter_mouse {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  padding: 10px 0 5px 0;
  border: 2px solid #e7e7e7;
  box-shadow: 0px 7px 10px 0px rgba(135, 141, 156, 0.5);
}

.desc {
  color: #878d9c;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.desc_enter_mouse {
  color: #3b3e45;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

@media (max-width: 1080px) {
  .menu_wrapper {
    height: 50px;
    top: 70px + 70px;
  }

  .contacts {
    display: none;
  }
}
