@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.row {
  border-right: 0.5px solid rgba(190, 190, 190, 0.8);
  color: #3b3e45;
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.status {
  color: #479323;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid #479323;
  background: rgba(71, 147, 35, 0.2);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status_disabled {
  color: #b8441f;
  border: 1px solid #b8441f;
  background: rgba(184, 68, 31, 0.2);
}

.table {
  text-align: center;
  &_wrapper {
    margin-bottom: 50px;
  }
}
.headerColumn {
  text-align: center;
  width: 100%;
}

.title {
  @include title_admin;
  &__text {
    width: 25%;
  }
  &__search {
    width: 50%;
    button {
      backgroundcolor: "#1677ff";
    }
  }
}
