@import "../../scss/variables.scss";

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin-left: 10px;
  }
}

.product {
  position: relative;
  height: calc(100% - 80px);
  overflow-y: scroll;

  /*   display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.buttons {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  button {
    border-radius: 8px;
    background: var(--main, #005fef);
    box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
    color: #fff;
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 40px;
    width: 180px;
  }
}
