@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.footer {
  color: #878d9c;
  text-align: right;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: end;
  &_price {
    color: #3b3e45;
    font-size: 18px;
    font-weight: 600;
  }
  &_status {
    font-size: 13px;
    color: #005fef;
    border-radius: 5px;
    border: 1px solid var(--main, #005fef);
    background: rgba(0, 95, 239, 0.1);
    width: 74px;
    height: 20px;
    text-align: center;
    margin-bottom: 15px;
  }
}

.feedback {
  &_header {
    color: #3b3e45;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
    padding: 20px;
  }
  &_who {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 14px;
    span {
      color: #878d9c;
      font-size: 12px;
      margin-left: 5px;
    }
  }
}
