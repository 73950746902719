@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.wrapper {
  background: linear-gradient(180deg, #f6f6f6 0%, #e8e8e8 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100vh;
  display: relative;
}

.bottomImg {
  position: absolute;
  bottom: 0;
  right: 0;
}

.topImg {
  position: absolute;
  top: 0;
  left: 0;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__wrapper {
    width: 500px;
  }
}
