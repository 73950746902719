@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";
.menu_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 45px;
  //height: 100%;
}

.oneIcon {
  display: flex;
  align-items: center;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: inherit;
  span {
    margin-left: 5px;
  }
}

.logout {
  width: 190px;
  cursor: pointer;
}
