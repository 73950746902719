@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";
.feedback {
  width: 100%;
  color: #3b3e45;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  &_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &_title {
    color: #3b3e45;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_block {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
    padding: 20px;
    margin-bottom: 30px;
  }
  &_rate {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #878d9c;
  }
  &_who {
    font-weight: 600;
    margin-bottom: 10px;
    span {
      color: #878d9c;
      font-size: 12px;
      margin-left: 5px;
    }
  }

  &_text {
    color: #3b3e45;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.feedback_button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  div:last-child {
    margin-left: 5px;
  }
}

@mixin def_but_item {
  height: 38px;
  margin-top: 15px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(135, 141, 156, 0.2);
}
.button_item {
  @include def_but_item;
  background: #005fef;
  display: flex;
  align-items: center;
  width: 200px;
}

.textAreaWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.fb {
  width: 100%;
  display: flex;
  justify-content: center;
}

.oneItemFeedback {
  border-radius: 10px;
  background: #fff;

  /* card shadow */
  box-shadow: 0px -5px 12px 0px rgba(135, 141, 156, 0.1);
  padding: 15px 15px;
  color: var(--text, #3b3e45);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pagination {
  position: relative;
  width: 100%;
}
