@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.layout {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1920px;
  background-color: green !important;
  margin-left: 50%;
  transform: translateX(-50%);
}
.sider {
  width: 280px;
  max-width: 280px;
  background: #fff !important;
  box-shadow: 0px 20px 13px 0px rgba(135, 141, 156, 0.1) !important;
  color: #3b3e45;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
}

.content {
  width: calc(100% - 280px);
  margin-left: 280px;
  max-width: 1650px;
  background: #f6f6f6;
  height: 100vh; //???
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
  overflow-x: visible;
  padding: 0 10px;
}
