
@import "react-alice-carousel/lib/alice-carousel.css";
@import "react-credit-cards-2/dist/es/styles-compiled.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ant-rate {
  color: #F15A29;
}



.ant-table-thead {
  color: var(--icon, #878D9C);
font-family: Manrope;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
  border: 0.5px solid rgba(190, 190, 190, 0.80);
  text-align: center;
}

.ant-pagination {
  position: fixed;
  bottom: -16px;
  right: 10px;
  background: #F6F6F6;
  width: calc(100% - 280px - 10px);
  padding: 0 5px;
  height: 50px ;
  padding-top:10px ;
}

.ant-table-thead tr th {
  background-color: #EAEAEA !important;
 
}

.ant-upload-list-item-container {
  width: none;
  height: none;
  max-width: 400px;
  max-height: 400px;
}

.ant-upload-list-picture-card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-selector {
 background-color: #f6f6f6 !important;
 
}

.ant-input-search :where(.css-dev-only-do-not-override-pr0fja).ant-input-group .ant-input-affix-wrapper:not(:last-child)  {
height: 40px;
background-color: #f6f6f6 !important;

}

.ant-input  {
  background-color: #f6f6f6 !important;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-input-search .ant-input-search-button {
/*   height: 40px;
  background-color: #f6f6f6 !important; */
}

#proxy-renderer {

  overflow: hidden;
}

.buttonUpload {
  margin-bottom: 20px;
}

.ant-form-item-required, label {
  font-family: "Manrope" !important;

  font-style: normal !important;
  font-weight: 500 !important;
  color: #9d9fa2 !important;

}

.ant-col .ant-form-item-label .css-dev-only-do-not-override-pr0fja {
padding: 0;
}


